import { Link } from "react-router-dom"

export default function Connect() {
  return (
    <div>
      <div className="Connect-body"><a href="https://lordover.substack.com/" target="_blank" rel="noreferrer noopener">SUBSTACK</a>{'\n'}</div>
      <div className="email-link"><a href="mailto:lord@lordover.net">LORD@LORDOVER.NET</a></div>
    </div>
  )
}
