import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'

// pages
import Home from './pages/Home'
import Music from './pages/Music'
import Connect from './pages/Connect'
import backgroundVideo from './face.mp4'

function App() {
  return (
    <div className="App">
      <video
        className="bgVideo"
        autoPlay
        loop
        muted
        width="100%"
        height="100%"
      >
        <source src={backgroundVideo} type='video/mp4'/>
      </video>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/music">
            <Music />
          </Route>
          <Route path="/connect">
            <Connect />
          </Route>
        </Switch>
        <div className="App-footer">
          <Link  style={{textDecoration:'none'}} to="/">HOME</Link> | <Link  style={{textDecoration:'none'}} to="/music">MUSIC</Link> | <Link  style={{textDecoration:'none'}} to="/connect">CONNECT</Link>
        </div>
      </BrowserRouter>
    </div>
  )
}

export default App